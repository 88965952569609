import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
 
  {
		path: "/",
		component: () => import('../views/blog.vue'),
		name:'blogs'
    },
	{
		path: "/blog",
		component: () => import('../views/blog.vue'),
		name: "blog",
		meta: {
			keepAlive: true // 需要被缓存
		}
	},
	{
	  path: "/blog/:id",
	  component: () => import('../views/detail.vue'),
	  name: "blog-detail"
	},
	
	{
	  path: "/link",
	  component: () => import('../views/link.vue'),
	  name: "link"
	},
	{
	  path: "/message",
	  component: () => import('../views/message.vue'),
	  name: "message"
	},
	{
	  path: "/about",
	  component: () => import('../views/about.vue'),
	  name: "about"
	},
	
	{
	  path: "/login",
	  component: () => import('../views/user/login.vue'),
	  name: "login",
	  },
	
	{
	  path: "/register",
	  component: () => import('../views/user/register.vue'),
	  name: "register"
	},
	{
	  path: "/password",
	  component: () => import('../views/user/password.vue'),
	  name: "password"
	},
	{
	  path: "/person",
	  component: () => import('../views/user/person.vue'),
	  name: "person"
	},
	
	{
	  path: "/recover",
	  component: () => import('../views/user/recover.vue'),
	  name: "recover"
	},
   {
     path: '*',
     component: () => import('../views/404.vue'),
	 name:'NotFound'
   },
   {
     path: 'error',
     component: () => import('../views/404.vue'),
   	 name:'NotFound'
   },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
