 function format(format) {
 	var myDate = new Date();
 	var timestamp3 = Date.parse(new Date(format));;
 	//将时间戳，设置为对象的时间（不设置为当前时间）
 	myDate.setTime(timestamp3);
 	return myDate.getFullYear();
 }


 module.exports = {
 	format
 }