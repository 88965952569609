import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Apis from '@/common/api'
import Func from '@/common/func'


//引入所有的api方法
Vue.prototype.$Apis = Apis
//引入所有的func方法
Vue.prototype.$Func = Func


//引入扩展库
import './plugins/mavonEditor.js'
import './plugins/iview.js'
import './plugins/highlightjs'

// 全局自定义组件
import MyLoading from './components/MyLoading'
Vue.use(MyLoading)

// import MyPage from './components/MyPage'
// Vue.use(MyPage)

import NewPage from './components/NewPage'
Vue.use(NewPage)

import TextLoading from './components/TextLoading'
Vue.use(TextLoading)

// 自定义指令
import '@/directive/index.js'
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
