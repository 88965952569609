import request  from './http.js'


const apis = {
	//获取文章列表
	getArticleList:(data) => {
		return request({
			url:'/article/getArticleList',
			method:'get',
			data:data,
			
		})	
	},
	
	getArticleDetail:(data) => {
		return request({
			url:'/article/getArticleDetail',
			method:'get',
			data:data,
			
		})	
	},
	
	getTagsList:() => {
		return request({
			url:'/article/getTagsList',
			method:'get',
		})
	},
	
	getBannerList:() => {
		return request({
			url:'/article/getBannerList',
			method:'get',
		})
	},

	getLinkList:() => {
		return request({
			url:'/article/getLinkList',
			method:'get',
		})
	},
	
	getCommentList:(data) => {
		return request({
			url:'/comment/getCommentList',
			method:'get',
			data:data
		})
	},
	addComment:(data) => {
		return request({
			url:'/comment/addComment',
			method:'post',
			data:data
		})
	},
	
	register:(data) => {
		return request({
			url:'/user/register',
			method:'post',
			data:data
		})
	},
	login:(data) => {
		return request({
			url:'/user/login',
			method:'post',
			data:data
		})
	},
	
	getUserDetail:() => {
		return request({
			url:'/user/getUserDetail',
			method:'get',
		})
	},
	
	updatePassword:(data) => {
		return request({
			url:'/user/updatePassword',
			method:'post',
			data:data
		})
	},
	
	replyComment:(data) => {
		return request({
			url:'/comment/replyComment',
			method:'post',
			data:data
		})
	},
	
	delComment:(data) => {
		return request({
			url:'/comment/delComment',
			method:'post',
			data:data
		})
	},
	
	delCommentReply:(data) => {
		return request({
			url:'/comment/delCommentReply',
			method:'post',
			data:data
		})
	},
	giveLike:(data) => {
		return request({
			url:'/article/giveLike',
			method:'post',
			data:data
		})
	},
	
	
	getMessageList:(data) => {
		return request({
			url:'/message/getMessageList',
			method:'get',
			data:data
		})
	},
	addMessage:(data) => {
		return request({
			url:'/message/addMessage',
			method:'post',
			data:data
		})
	},
	
	
	replyMessage:(data) => {
		return request({
			url:'/message/replyMessage',
			method:'post',
			data:data
		})
	},
	
	delMessage:(data) => {
		return request({
			url:'/message/delMessage',
			method:'post',
			data:data
		})
	},
	
	delMessageReply:(data) => {
		return request({
			url:'/message/delMessageReply',
			method:'post',
			data:data
		})
	},
	
	sendEmail:(data) => {
		return request({
			url:'/user/sendEmail',
			method:'post',
			data:data
		})
	},
	forgetPassword:(data) => {
		return request({
			url:'/user/forgetPassword',
			method:'post',
			data:data
		})
	},
	getMyComment:(data) => {
		return request({
			url:'/comment/getMyComment',
			method:'get',
			data:data
		})
	},
	
	getMyMessage:(data) => {
		return request({
			url:'/message/getMyMessage',
			method:'get',
			data:data
		})
	},
	
	getBanner:() => {
		return request({
			url:'/system/getBanner',
			method:'get',
		})
	},
	
	getConfig:() => {
		return request({
			url:'/system/getConfig',
			method:'get',
		})
	},
	
	
	
	
	






}
export default apis;

