import Apis from '@/common/api'
import Func from '@/common/func'
const state = {
  webinfo: JSON.parse(localStorage.getItem('webinfo'))||{},
  tag: "",
  classify: "",
  page:1,
  banners: JSON.parse(localStorage.getItem('banners'))||[],
  //获取本地存储token
  token:localStorage.getItem('token')||'',
  user :JSON.parse(localStorage.getItem('user'))||{},
};



// 获取state的数据
const getters = {
  webInfo: state => state.webinfo,
  tag: state => state.tag,
  classify: state => state.classify,
  banners: state=> state.banners,
  user: state=> state.user,
  token:state=> state.token,
  page:state=> state.page,
}

// 更新state的数据
const mutations = {
  WEBINFO(state, data) {
    state.webinfo = data
  },
  TAG(state, data) {
    state.tag = data
  },
  CLASSIFY(state, data) {
    state.classify = data
  },
  BANNERS(state, data) {
    state.banners = data
  },
  USER(state, data) {
    state.user = data
  },
  TOKEN(state, data) {
    state.token = data
  },
  PAGE(state, data) {
    state.page = data
  }


}

// 更新state数据的动作
const actions = {
  async WebInfo({ commit }) {
	 Apis.getConfig().then(res=>{
			  console.log(4444,res);
			res.data.start_year=Func.format(res.data.web_start_date)
			commit('WEBINFO', res.data)
			localStorage.setItem('webinfo',JSON.stringify(res.data));
	 })
	
  
  },
  Tag({ commit }, data) {
    commit('TAG', data)
  },
  Classify({ commit }, data) {
    commit('CLASSIFY', data)
  },
  async Banners({ commit }) {
	  Apis.getBanner().then(res=>{

	  		commit('BANNERS', res.data)
			localStorage.setItem('banner',JSON.stringify(res.data));
	  })
  },
  
  async GetUser({ commit }) {
  	  Apis.getUserDetail().then(res=>{
  	  		commit('USER', res.data)
			localStorage.setItem('user',JSON.stringify(res.data));
  	  })
  },
  Token({ commit }, data) {
    localStorage.setItem('token',data);
    commit('TOKEN', data)
  },
  
  Page({ commit }, data) {
    commit('PAGE', data)
  },
  
 Logout({ commit }, data) {
   localStorage.removeItem('token');
   commit('TOKEN', '')
   commit('USER', {})
 },
 
}


export default {state, getters, mutations, actions}

