  import axios from 'axios'
  import store from "../store/index"
  import { Notice} from 'iview'
axios.defaults.baseURL =process.env.VUE_APP_BASRURL

  /**
     * @params string url  要请求的url地址
     * @params string method 要请求的类型
	 * @params object data 要请求的数据
     * @params object headers 要请求的请求头数据
   */
function request({url,method='get',data={},headers={}}){
       let request = {
            url: url,
            method: method, 
           	headers:{
				'Authorization':'Bearer ' + store.state.blog.token,
				'Content-Type': 'application/json',
				...headers
			}
       }
        if(method=='get') {
            request.params= data;
        }else{
             request.data= data;
        }
       
       return new Promise((resolve, reject) => {
          axios(request).then(res => {//请求成功,触发then中的函数
             // console.log(res)
              success(res,resolve, reject)
            }).catch(err=>{ // 请求失败, 触发catch中的函数
              console.log(751,err)
               //reject(err)
			   error(err,reject)
             })
        
        })
   }
   
   function success(res,resolve, reject){
	   if(res.data.code == 200){
			return resolve(res.data)
	   }else {
		   Notice.error({
		     title: '错误提示',
		     desc: res.data.msg,
		     duration: 2
		   })
		   return reject(res)
	   }
   }
  
  
  
  function error(err,reject){
	  // 服务器连接失败
	  Notice.error({
	    title: '网络提示',
	    desc: '服务器连接失败，请稍后再试',
	    duration: 2,
	  });
	   return reject(err)
  }
  
  
  
  export default request;