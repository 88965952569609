<template>
  <footer>
    <div class="foot-text">
      <a :href="webInfo.web_link" target="_blank" class="link">
        <Icon type="logo-github" />
      </a>
      <p>本站已坚毅的运行{{longTime}}</p>
      <p class="base">Crafted with <i class="iconfont lv-icon-aixin"></i> by 
        <span>@{{webInfo.web_username}}</span>&nbsp;&nbsp;{{webInfo.web_email}}
        <!-- <a class="green" href="https://github.com/sweida/laravel-blog-api" target="_blank">ThinkPHP</a> + 
        <a class="green" href="https://github.com/sweida/vue-blog-index" target="_blank">Vue</a> -->
      </p>
      <p>© {{webInfo.start_year}} - {{year}} &nbsp;&nbsp;{{webInfo.web_name}} &nbsp;&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff">{{webInfo.web_beian}}</a></p>
      
    </div>
  </footer>
</template>

<script>
let that;
	import {
		mapGetters
	} from "vuex"
export default {
	
  data () {
    return {
      longTime: '',
      year: (new Date()).getFullYear(),
    }
  },
  
  computed: {
  	
  ...mapGetters([
  	'webInfo'
  ]),
  },

  created() {
	 
	   that = this;
	    this.getRemainderTime();  
	   setInterval(()=>{
		   that.getRemainderTime()
	  }, 1000)
	
  },
  methods: {
	  getRemainderTime (){
		  let startTime =this.webInfo. web_start_date;
		  //created无法获取vuex 所以加了判断
		
		  if(!startTime){
			  return false;
		  }
		  let s1 = new Date(startTime.replace(/-/g, "/")),
		  s2 = new Date(),
		  runTime = parseInt((s2.getTime() - s1.getTime()) / 1000);
		  let day = Math.floor(runTime / 86400);
		  runTime = runTime % 86400;
		  let hour = Math.floor(runTime / 3600);
		  runTime = runTime % 3600;
		  let minute = Math.floor(runTime / 60);
		  runTime = runTime % 60;
		  let second = runTime;
		  that.longTime = day+'天'+hour+'小时'+minute+'分'+second+'秒';
		}
		
  }

}
</script>

<style scoped lang="scss">
footer{
  position :relative;
  background: #3d4852;
  }
.link{
  color :#fff;
  i{
    margin-bottom :15px;
    font-size :28px;
	}
  i:hover{
    color: #929292;
}
}
.foot-text{
  line-height :30px;
  z-index :20;
  position :relative;
  font-size :14px;
  text-align: center;
  // background: #93a6ab;
  width: 100%;
  padding: 15px 0 20px;
  color: #fff;
  .base{
    span, .green{
      color :#ffed4a;
      font-size: 16px;
      margin :0 2px;
	  }}
	  }
.lv-icon-aixin{
  color :red;
}
</style>
