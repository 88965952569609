<template>
	<header>
		<div class="header">
			<img src="@/assets/nav-map.jpg" class="footer-bg">

			<div class="menu">
				<div class="left">
					<div class="log-box" >
						
					
						<div class="logo">
							<img :src="webInfo.web_logo" alt="">
						</div>
						<div class="log-title">{{webInfo.web_name}}</div>
					</div>
					<!-- 菜单 -->
					<li v-for="(item, index) in nav" :key="index" :class="{active: $route.path==item.url}">
						<a @click="goRouter(item.url)">{{item.name}}</a>
					</li>
					<!-- <li>
						<a href="http://blog-doc.golang365.com" target="_blank">API文档</a>
					</li> -->
				</div>

				<div class="user" v-if="user.id">
					<Dropdown @on-click="changeMenu">
						<a href="javascript:void(0)" class="user-info">
							<img :src="user.avatar_url"
								onerror="this.src='https://avatars.dicebear.com/v2/identicon/id-undefined.svg'">
							{{user.name}}
							<Icon type="md-arrow-dropdown" />
						</a>
						<DropdownMenu slot="list">
							<DropdownItem name="person">
								<Icon type="md-person" />个人中心
							</DropdownItem>
							<DropdownItem name="changePasswd">
								<Icon type="md-settings" />修改密码
							</DropdownItem>
							<DropdownItem name="logout">
								<Icon type="md-exit" />退出登录
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</div>
				<div class="right" v-else>
					<router-link to="/login">登录</router-link>
					<span class="register">/</span>
					<router-link to="/register">注册</router-link>
				</div>
			</div>
		</div>

		<!-- 手机菜单 -->
		
		<div class="phone-logo-box">
			<div class="phone-logo">
				<img :src="webInfo.web_logo" />
			</div>
			<div class="logo-title">{{webInfo.web_name}}</div>
		</div>
	
		<Collapse simple v-model="mobnav" class="nav-content">
			<Panel name="1" hide-arrow>
				<span></span>
				<span></span>
				<span></span>
				<div class="mobliNav-main" slot="content">
					<img   v-imgUrl="banners.article" class="nav-bg">
					<li v-for="(item, index) in nav" :key="index" :class="{active: $route.path==item.url}">
						<Icon :type="item.icon" />
						<a @click="goRouter(item.url)">{{item.name}}</a>
					</li>
				

					<template v-if="user.id">
						<li>
							<img class="user-img"
								:src="user.avatar_url">
							{{user.name}}
							<Icon type="md-arrow-dropdown" />
						</li>
						<li class="second">
							<Icon type="md-person" />
							<router-link to="/person">个人中心</router-link>
						</li>
						<li class="second">
							<Icon type="md-settings" />
							<router-link to="/password">修改密码</router-link>
						</li>
						<li class="second">
							<Icon type="md-exit" />
							<a @click="changeMenu('logout')">退出登录</a>
						</li>
					</template>
					<template v-else>
						<li>
							<Icon type="logo-snapchat" />
							<router-link to="/login">登录</router-link>
						</li>
						<li>
							<Icon type="md-person-add" />
							<router-link to="/register">注册</router-link>
						</li>
					</template>
				</div>
			</Panel>
		</Collapse>
	</header>
</template>

<script>
	import {
		mapActions,
		mapGetters
	} from "vuex"

	export default {
		data() {
			return {
				nav: [{
						name: '文章',
						url: '/blog',
						icon: 'ios-book'
					},
					{
						name: '友链',
						url: '/link',
						icon: 'logo-octocat'
					},
					// {name: '打赏', url: '/donate', icon: 'logo-usd'},
					{
						name: '留言',
						url: '/message',
						icon: 'md-chatboxes'
					},
					{
						name: '关于我',
						url: '/about',
						icon: 'md-beer'
					},
				],
				mobnav: '2'
			}
		},
		computed: {
			...mapGetters([
				'user', 'classify', 'tag', 'banners','page','webInfo'
			]),
		},
		
		watch: {
			$route(to, from) {
				this.mobnav = '2'
			}
		},
		mounted() {
			console.log(5555,this.user)
		},
		methods: {
			...mapActions(['Logout']),
			goRouter(item) {
				// 当有选择标签或者分类时点击博客自动选择
				if (item == '/blog') {
					let query = {}
					if (this.classify) {
						query.classify= this.classify
					}else if (this.tag) {
						query.tag= this.tag
					} 
					if (this.page) {
						query.page= this.page
					}
					this.$router.push({
						path: '/blog',
						query: query
					})
				} else {
					this.$router.push({
						path: item
					})
				}
			},
			changeMenu(item) {
				if (item == 'changePasswd') {
					this.$router.push('/password')
				}else if (item == 'person') {
					this.$router.push('/person')
				}else if (item == 'logout') {
					
						this.$Notice.success({
							title: '退出成功',
							desc: '欢迎下次再来👏',
							duration: 3,
						});
						this.$router.push('/')
						this.Logout()
					
				}
			}
		}

	}
</script>

<style>
	/* 手机菜单 */
	.nav-content .ivu-collapse-header {
		height: 60px !important;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: center;
		width: 60px;
		position: absolute !important;
		right: 20px;
		top: -60px;
	}

	.nav-content .ivu-collapse-content {
		padding: 0 !important;
		border-radius: 0 !important;
		border-top: 1px solid #d8ddde;
	}

	.nav-content .ivu-collapse-content>.ivu-collapse-content-box {
		padding: 0 !important;
	}
</style>

<style scoped lang="scss">
	.header {
		display: flex;
		min-height: 60px;
		background-image: linear-gradient(167deg, #2b274b, #771787 49%, rgba(201, 28, 136, .91));
		color: #fff;
		position: relative;
		width: 100%;
		z-index: 10;

		.nav {
			display: flex;
			justify-content: flex-end;
			flex: 1;
		}

     .log-box{
		margin-right: 20px; 
		.logo {
			width: 60px;
			height: 60px;
			
		
			img {
				width: 100%;
				height: 100%;
			}
		}
		.log-title{
			font-size: 18px;
			    font-weight: 600;
		}
	 }
		
	}

	.footer-bg,
	.nav-bg {
		position: absolute;
		opacity: .25;
		height: 100%;
		width: 100%;
		object-fit: cover;
		mix-blend-mode: multiply;
	}

	.footer-bg {
		width: 100%;
	}

	.nav-bg {
		top: 0;
		display: none;
	}

	.header .menu {
		z-index: 20px;
		position: relative;
		// width: 1000px;
		width: 80%;
		margin: 0 auto;
		display: flex;
		color: #fff;
		justify-content: space-between;
		font-size: 14px;
		padding: 0 20px;
		font-size: 16px;

		div {
			display: flex;
			align-items: center;

			a {
				color: #fff;
			}
		}

		.left li {
			padding: 1px 15px;
			margin: 0 2px;
		}

		.left li.active {
			background: #7c1879;
			border-radius: 3px;
		}

		.left li a:hover {
			border-bottom: 1px solid;
			// text-decoration 
		}
	}

	.register {
		margin: 0 10px;
	}

	// 手机菜单
	.nav-content {
		z-index: 999;
		display: none;
		position: relative;
		background: transparent;
		border: 0 !important;

		span {
			background: #fff;
			margin: 2.5px;
			display: table;
			width: 25px;
			height: 3px;
			border-radius: 18%;
		}
	}

	.mobliNav-main {
		width: 100%;
		position: absolute;
		z-index: 999;
		overflow: hidden;
		background-image: linear-gradient(167deg, #2b274b, #771787 49%, rgba(201, 28, 136, .91));

		li {
			display: flex;
			position: relative;
			z-index: 20;
			align-items: center;
			padding: 10px 20px;
			color: #fff;

			.user-img {
				width: 20px;
				height:20px;
				border-radius: 50%;
				margin-right: 8px;
			}

			i {
				font-size: 18px;
				margin-right: 10px;
			}

			a {
				width: 100%;
				color: #fff;
				font-size: 14px;
			}

			a:hover {
				color: #6289ad;
			}
		}

		li.active {
			background: rgba(166, 37, 141, .4);
		}

		.second {
			padding-left: 40px;
		}
	}

	.phone-logo-box {
		display: none;
	}

	.user {
		.user-info {
			display: flex;
			align-items: center;
		}

		img {
			width: 40px;
			height: 40px;
			margin-right: 10px;
			border-radius: 50%;
			background: #fff;
		}

		.ivu-dropdown-item {
			font-size: 14px !important;
		}

		i {
			margin-right: 10px;
		}
	}

	@media screen and (max-width: 750px) {
		.header {

			.footer-bg,
			.menu {
				display: none;
			}
		}

		.nav-bg,
		.nav-content {
			display: block;
		}

		.phone-logo-box{
			position: absolute;
			top: 0px;
			z-index: 20;
			left: 20px;
			display: flex;
			align-items: center;
			.phone-logo {
				display: block;
				width: 60px;
				height:60px;
				img {
					width: 100%;
				}
			}
			.logo-title{
				color: white;
				    font-size: 18px;
				    font-weight: 600;
			}
		}
		
	}
</style>
