<template>
  <div id="app">
    <Headnav></Headnav>
    <section class="content blog">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" class="slide animate03" ></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" class="slide animate03" ></router-view>
      <BackTop :height="300" :bottom="100">
        <div class="top animate03">
          <Icon type="ios-arrow-up" />
        </div>
      </BackTop>
    </section>
     <Footer></Footer> 
  </div>
</template>

<script>
import Headnav from './components/headnav'
 import Footer from './components/footer'
import './assets/style/index.scss'
import './assets/style/hybrid.scss'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'app',
  components: {
    Headnav,
    Footer,
  },
 
  data() {
    return {}
  },
  created() {

	let webInfo = JSON.parse(localStorage.getItem('webinfo'))||{};
	let token = localStorage.getItem('token')||'';
	let user = JSON.parse(localStorage.getItem('user'))||{};
	let banners = JSON.parse(localStorage.getItem('banners'))||[];
	
	 console.log(333,token);
	 console.log(333,user);
     // 没有user时才请求用户信息
   if (token && !user.id) {
	  
      this.GetUser()
   }
	
	 //没有网站信息，获取网站信息
	if (!webInfo.name) {
	
	console.log(4444,webInfo);
       this.WebInfo()
	}
    //没有网站轮播信息，获取网站轮播
    if (banners.length <1) {
		this.Banners()
	}
	
	

  },
  
  
  methods: {
    ...mapActions(['GetUser', 'WebInfo','Banners']),
  },
 
}
</script>

<style lang="scss">
html, body, #app{
  height :100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  }
section.content{
  flex :1;
}
.ivu-back-top{
  z-index :30 !important;
  }
.top{
  background: #b8297e;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  }
.top:hover{
  background: #64156e;
  }
.ivu-back-top i{
  padding: 6px 8px !important;
}
.slide {
  animation: slide .8s;
}
@keyframes slide{
  0%{
    opacity:0.3;
    transition: all .5s cubic-bezier(.19,1,.22,1);
    transform: translate3d(0,-20px,0);
    // transform:translateY(-20px)
  }
  100%{
    opacity:1;
    transition: all .5s cubic-bezier(.22,1,.27,1);
    transform: translateZ(0);
    // transform:translateY(0)
  }
}

@media screen and (max-width: 640px){
  .ivu-back-top{
    bottom: 80px !important;
    right: 20px !important;
	}
  .top{
    background: #b8297e;
	}
	}
</style>
